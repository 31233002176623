  /* about-us-section */
  
  .about-us-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background-color: #eef3f5;
    font-family: 'Manrope';
  }

  
  .about-us-content {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .about-us-content h2 {
    color: #1c4753;
    margin-bottom: 10px;
  }
  
  .about-us-content p {
    color: #333;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 2;
    font-weight: 500;
  }
  #arrow{
    height: 100px;
    width: 200px;
  }

  .philosophy{
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    gap: 0px;

  }
  .philosophies{
    height: 150px;
    width: 350px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    row-gap: 10px;
  }

  #time{
    /* background: url(../../images/luxa.org-opacity-changed-clock.jpg) no-repeat center center/cover;  ; */
    background-color: #1c4753;
    text-align: center;
  }
  #delivery{
    /* background: url(../../images/luxa.org-opacity-changed-delivery.jpg) no-repeat center center/cover;  ; */
    background-color: #2c5d6a;;
    text-align: center;
  }
  #plan{
    /* background: url(../../images/luxa.org-opacity-changed-plan.jpg) no-repeat center center/cover;  ; */
    background-color: #2c5d6a;;
    text-align: center;
  }
  #flight{
    /* background: url(../../images/luxa.org-opacity-changed-flight-options.jpg) no-repeat center center/cover;  ; */
    background-color: #1c4753;;
    text-align: center;
  }
 
  @media (max-width: 768px) {
    .about-us-container {
      flex-direction: column;
      padding: 30px 10px;
      height: auto;
    }
  
    .about-us-content {
      text-align: center;
    }
  
    .philosophy {
      grid-template-columns: 1fr;
      gap: 0px;
    }
  
    .philosophies {
      height: 200px;
      font-size: 20px;
    }

  #time{
    /* background: url(../../images/luxa.org-opacity-changed-clock.jpg) no-repeat center center/cover;  ; */
    background-color: #1c4753;
    text-align: center;
  }
  #delivery{
    /* background: url(../../images/luxa.org-opacity-changed-delivery.jpg) no-repeat center center/cover;  ; */
    background-color: #2c5d6a;;
    text-align: center;
  }
  #plan{
    /* background: url(../../images/luxa.org-opacity-changed-plan.jpg) no-repeat center center/cover;  ; */
    background-color: #1c4753;
    text-align: center;
  }
  #flight{
    /* background: url(../../images/luxa.org-opacity-changed-flight-options.jpg) no-repeat center center/cover;  ; */
    background-color: #2c5d6a;
    text-align: center;
  }
  }