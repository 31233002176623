/* Container styling */
#about{
  background-color: white;
}
.about-container {
  padding: 20px;
  margin: 0px;
  font-family: "Manrope";
  background-color: #eef3f5;
  color: #1c4753;
}

/* About hero section */
.about-hero-section {
  padding: 10px;
  gap: 10px;
  display: grid;
  /* grid-template-columns: 0.5fr 1fr; */
  align-items: stretch;
}


.underscore{
  height: 5px;
  width: 100px;
  background-color: #1c4753;
  border-radius: 10px;
}
.about-header-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
  color: #1c4753;
}
.about-members{
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
  flex-wrap: wrap;
  margin: 40px 0px;
}
.about-members img{
  width: 70px;
  object-fit: contain;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}
.partners{
  padding: 30px 10px;
}


/* Text content styling */
.about-text-content {
  text-align: left;
  font-family: "Manrope";
  margin: 20px 0px;
  line-height: 2;
  font-weight: 700;
  background-color: #1c4753;
  color: white;
  padding: 20px;
  min-width: 350px;
}
.about-img{
  margin: 0px 0px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 2px 2px whitesmoke;
  border: none;
}
.img-container{
  background-color: #eef3f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagline{
  background-color: white;
  color: #1c4753;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  /* width: 60px; */
  /* height: 60px; */
  border-radius: 5px;
  font-size: 14px;
  margin: 20px 0px;
}
/* .tagline img{
  width: 30px;
  object-fit: contain;
} */

.read-more-span {
  color: #00abe9;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px; /* To add a bit of space before the text */
  border: none;
  background: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

@media (max-width: 768px) {

  .about-container {
    padding: 0px;
  }

  .about-hero-section {
    /* padding: 10px; */
    /* gap: 10px; */
    display: block;
    /* grid-template-columns: 0.5fr 1fr; */
    align-items: stretch;
  }
  .about-img{
    margin-top: 0px;
    min-width: 350px;
  }
  
  .about-text-content {
    text-align: left;
    font-family: "Manrope";
    margin-top: 0px;
    text-align: center;
    align-items: center;
    margin: 20px 0px 0px 0px;
  }
}


