.reasons {
  padding: 40px;
  background: #eef3f5;
  text-align: center;
  margin: 0px auto;
}

.reasons .title {
  margin-bottom: 40px;
  font-size: 19px;
  font-weight: 700;
  line-height: normal;
  color: #11282b;

}

.reason-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.reason-box {
  background: #fff;
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reason-box:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.reason-box i {
  font-size: 40px;
  color: #1c4753 !important;
  margin-bottom: 10px;
}

.reason-box h3 {
  color: #333 !important;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;

  
}
.reasons h4{
  color: #1c4753 !important;
  font-size: 36px;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 10px;
}

.reason-box p {
  color: #666;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 767px) {
  .reason-grid {
    grid-template-columns: 1fr;
  }
}

#why {
  color: #cb4154;
}
