.booking-header-main{
  margin-bottom: 30px;
  margin-top: -25vh;
  z-index: 100;
  position: relative;
}

.booking-form-container {
  background-color: #b2a8a8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Reduced width */
  margin: 0 auto;
  position: relative;
  top: -80px;
  height: 30vh;
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown-container label {
  margin-right: 10px;
}

#service-select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-group {
  flex: 1 1 100px; /* Reduced width */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px; /* Uniform height */
}

.btn-search {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 30px 10px 0 5px;
  align-self: center;
  flex-shrink: 0; 
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* booking page */

.booking-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial, sans-serif';
  padding: 20px;
}

.booking-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
}

.flight-details,
.user-details {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px  rgb(30, 115, 190);
}

.flight-details {
  flex: 1;
  margin-right: 20px;
}

.flight-details h2 {
  font-size: 24px;
  margin-bottom: 20px;
  background-color:  rgb(30, 115, 190);;
  color: #fff;
  padding: 10px;
}

.flight-details table {
  width: 100%;
  border-collapse: collapse;
}

.flight-details td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.user-details {
  flex: 1;
  margin-left: 20px;
}

.user-details h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #DAA520;
}

.user-details .formm-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.user-details .formm-group input,
.user-details textarea {
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-details textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.user-details .formm-group input[type="checkbox"] {
  width: auto;
}

.user-details .formm-group label {
  margin-left: 5px;
}

.user-details button {
  background-color: #DAA520;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.user-details button:hover {
  background-color: #C0C0C0;
}

.congrats-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  color: #3c763d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.congrats-message span:first-child {
  font-size: 18px;
  margin-right: 10px;
}

.close-btn {
  cursor: pointer;
  font-size: 18px;
}

/* Booking Components */
.header-container{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  z-index: 1;
}
.header-main{
  min-width: 300px;
  background-color: transparent;
}
.header-flex{
  display: flex;
  column-gap:2px;
  row-gap: 0px;
  margin: 0px;
  flex-wrap: nowrap;
  color: white;
  background-color: transparent;
}
.header-tab{
  font-size:15px;
  font-weight: bold;
  display: flex;
  column-gap: 5px;
  cursor: pointer;
  padding: 20px 30px 20px 10px;
  min-width: 40px;
}
.header-tab-active{
  color: white;
  background-color: #22516f;
}

.header-tab-inactive{
  color: #22516f;
  background-color: white;
  opacity: 0.5;
}
.header-container-main{
  background-color: #22516f;
  box-shadow: #22516f 1px 2px 2px;
  min-height: 400px;
  border: none;
  color: white;
  padding:20px 10px 10px 30px
}
.header-title{
  margin: 0px;
}
.header-subtitle{
  font-size: 10px;
}
.trip-type{
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.trip-type label{
  font-size: 15px;
  font-weight: bold;
}
.trip-type input[type='radio']{
  accent-color: #22516f;
  margin-top: -2px;
  font-size: 15px;
}
.trip-detail-header{
  font-weight: bold;
  margin: 30px 0px 10px 0px;

}
.header-container-main-2 input{
  height: 40px;
  width: 300px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  padding-left: 10px;
}
.header-container-main select{
  height: 40px;
  width: 310px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  padding-left: 10px;
}
.iconCircle{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: whitesmoke;
  color: #22516f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
}
.simple-flex{
  display: flex;
  align-items: center;
  row-gap: 20px;
  flex-flow: row wrap;
}
.simple-flex-single{
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}
.scheduleButton{
  background-color: white;
  color: #22516f;
  width: 100%;
  margin: 30px 0px 10px 0px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  padding: 15px 0px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .scheduleButton{
    width: 90%;
  }
  .booking-form-container {
    padding: 15px;
    top: -100px;
    height: 80vh;
  }

  .form {
    gap: 10px;
  }

  .form-group {
    flex: 1 1 100%; 
  }
  body {
    font-family: Arial, sans-serif;
    /* background-color: #f5f5f5; */
    margin: 0;
    padding: 0;
}
.iconCircle{
  display: none;
}
}