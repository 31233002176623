body {
    font-family: "Manrope";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  section.services {
    padding: 50px 20px;
    background-color: #fff;
  }
  
  .services-container {
    align-items: center;
    margin: 20px 0px;
  }
  
  .services-header {
    margin-right: 20px;
    justify-content: center;
    text-align: center;
    color: #000;
  }
  
  .services-header h4 {
    color: rgb(30, 115, 190);
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .services-header h2 {
    font-size: 19px;
    font-weight: 700;
    line-height: normal;
    color: #333;
    margin-bottom: 15px;
  }
  
  .services-header p {
    font-size: 16px;
    /* margin-bottom: 20px; */

  }
  
  .services-header button {
    background-color: #11282b;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .left-arrow, .right-arrow {
    position: absolute;
    top: 50%;
    font-size: 2rem;
    color: #000;
    background: none;
    border: none;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  .slide-wrapper {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 30px 0px;
    column-gap: 20px;
    scrollbar-color: #1c4753 white;
  }
  
  .service-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px #1c4753;
    text-align: left;
    padding: 20px;
    width: 200px;
    height: 200px;
  }
  .service-card img{
    height: 150px;
    width: 200px;
    object-fit: cover;
  }
  
  .service-card h3 {
    font-size: 19px;
    font-weight: 700;
    line-height:normal;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
.review-slide {
  overflow: hidden;
  width: 100%;
  min-height: 250px;
}
  @media (max-width: 768px) {
   
  .slide-wrapper {
    padding: 10px 0px;
    column-gap: 10px;
  }
  }