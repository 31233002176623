/* src/components/partners.css */
.logo-slider {
    overflow: hidden;
    width: 85%;
    margin-top: 60px;
    margin: 40px auto; 
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    animation: scroll 60s linear infinite; 
  }
  
  .logo {
    flex-shrink: 0;
    max-width: 150px;
    margin: 0 20px;
    transition: transform 0.3s;
  }
  
  .logo:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .logo-container {
      flex-wrap: nowrap;
      justify-content: center;
    }
  
    .logo {
      margin: 10px;
    }
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--total-width) / 2));
    }
  }
  