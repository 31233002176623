.footer {
    background-color: #000;
    color: #fff;
    padding: 40px 0;
    font-family: 'Manrope';
  }
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #111;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    margin-right: 10px;
  }
  
  .help {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .help a {
    color: #ffa500;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
  }
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin: 20px 0;
  }
  
  .footer-section h4 {
    margin-bottom: 20px;
    color: #077096;
  }
  
  .footer-section p {
    margin: 10px 0px;
    line-height: 30px;
    font-size: 16px;
  }
  
  .footer-section ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 0;
    list-style: none;
    margin: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
    color: #077096;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #333;
    padding-top: 20px;
    font-size: 14px;
  }
  #textarea{
    width: 100%;
  }
  
 
@media (max-width: 768px) {
  .footer-container {
      flex-direction: column;
      text-align: center;
  }

  .footer-section {
      margin: 10px 0;
  }

  .footer-section ul {
      grid-template-columns: 1fr;
      justify-items: center;
  }

  .footer-section ul li {
      text-align: center;
  }
  #textarea{
    width: 50%;
  }
}