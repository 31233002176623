/* src/components/Header.css */

header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-bottom: 2px solid #ddd;
  z-index: 10;
  height: 5vh;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  animation: logo-anime 3s linear 0s infinite;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 20px;
}

nav a {
  text-decoration: none;
  color: #4fbde6;
}

nav li a:hover {
  text-decoration: wavy;
  border-bottom: 3px solid white; 
}

header .logo img {
  width: 100px;
  margin-top: 20px;
}

.actions .btn {
  background-color: #1cb8f1;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  justify-items: center;
  align-items: center;
  font-weight: bold;
  width: 140px;
  height: 50px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.btn:hover {
  background-color: #a5d4e6;
}

.fab {
  font-size: 1.5rem;
  color: #077096 !important;
  gap: 20px;
}

.fab:hover {
  color: white !important;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

/* New Header */
.menu-header{
  background-color: #0d4352;
  opacity: 0.93;
  position: fixed;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
  z-index: 10;
}

.enquiries{
  background-color: white;
  color: #12657C;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
  margin-right: 30px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
.single-menu{
  padding: 5px 20px;
  color: white;
}


.p-link{
  color: #12657C;
  text-decoration: none;
}






/* Mobile */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
    margin: 0px 10px;
  }

  nav {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #0d4352;
    text-align: center;
  }

  nav.open {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  nav ul {
    flex-direction: column;
    gap: 0;
  }

  nav ul li {
    padding: 15px 0;
   
  }

  nav a {
    color: #333;
    bottom: 1px solid #333;
  }

  .social-icons {
    display: none;
  }
}

@keyframes logo-anime {
   to{
    scale: 1.05;
   }
}