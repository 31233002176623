.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  background-color: #1c4753;
  padding: 0px 0px 0px 0px;
  text-align: left;
  font-family: "Manrope";
  height: 75vh;
  overflow: hidden;
  /* background: url(../../images/slide-five.jpg) no-repeat center center/cover; */
  transition: background-image 0.5s ease-in-out;
}
.passport-image{
  background-color: #1c4753;
  background: url(../../images/about-passport.jpg) no-repeat center center/cover;
}
.slide-one{
  background: url(../../images/slide-one.jpg) no-repeat center center/cover;
}
.slide-two{
  background: url(../../images/slide-two.png) no-repeat center center/cover;
}
.slide-three{
  background: url(../../images/slide-three.jpg) no-repeat center center/cover;
}
.slide-four{
  background: url(../../images/slide-four.png) no-repeat center center/cover;
}
.slide-five{
  background: url(../../images/slide-five.jpg) no-repeat center center/cover;
}

.hero-content {
  text-align: left;
  padding: 0px 0px 0px 5%;
}

.subheading {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
  width: auto;
  color: #EE893D;
  background-color: #ebf4f6;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  text-align: left;
  text-wrap: nowrap;
}
.simple-flex {
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: bold;
  font-size: 12px;
}

h1 {
  font-size: 44px;
  font-weight: 800;
  line-height: 58px;
  margin-bottom: 20px;
  margin-top: 20px;
}

ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 20px;
  margin-bottom: 20px;
}
li {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

li img {
  margin-right: 10px;
  height: 30px;
  width: 30px;
}
.actions {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.btn {
  padding: 15px 30px;
  border: none;
  cursor: pointer;
}

.btn-primary {
  background-color: #ffd700;
  color: #333;
}

.btn-secondary {
  background-color: #fff;
  color: #333;
}

#button {
  width: 150px;
  height: 60px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}
#button:hover {
  background-color: #a5d4e6;
}
.whatsapp-chat-container {
  width: 210px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  background-color: white;
  padding: 10px 15px;
  border-radius: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  color: #25d366; /* WhatsApp green color */
}

.whatsapp-icon {
  background-color: #25d366;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.whatsapp-icon .fa-whatsapp {
  color: white;
}

.whatsapp-chat-label {
  font-size: 16px;
  color: #000;
}
/* Hero Page */
.hero-backdrop {
  background-color: black;
  opacity: 0.3;
  width: 100%;
  height: 75vh;
  z-index: 0;
}
.toplevel-bg {
  width: 100%;
  height: 75vh;
}
.top-layer {
  width: 100%;
  opacity: 1;
  position: absolute;
  color: white;
  z-index: 1;
  margin-top: 90px;
}
@media (max-width: 768px) {
  .hero-content{
    margin-top: 10px;
  }
  .subheading {
    font-size: 11px;
  }
  .hero {
    text-align: center;
  }


  h1 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  ul {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  li {
    font-size: 16px;
  }

  li img {
    height: 20px;
    width: 20px;
  }

  .whatsapp-chat-container {
    width: 200px;
    padding: 10px;
    margin: 20px auto;
  }

  .whatsapp-icon {
    margin-right: 5px;
  }

  .whatsapp-chat-label {
    font-size: 14px;
  }
}

@media (max-width: 468px) {
  .hero-content{
    margin-top: 10px;
  }
  .hero {
    padding: 0px;
  }

  h1 {
    font-size: 28px;
    line-height: 36px;
  }

  li {
    font-size: 14px;
  }

  li img {
    height: 18px;
    width: 18px;
  }

  .whatsapp-chat-label {
    font-size: 12px;
  }
}