body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #f5f5f5; */
}

.contact {
  margin: 0 auto;
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.map-section {
  
}

.contact-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #1c4753;
  padding: 30px 30px;
  color: white;
  margin-left: 50px;
  max-width:450px ;
}
.contact-section input {
  width: 98%;
  margin: 10px 0px;
  height: 40px;
}
.contact-section textarea {
  width: 98%;
  margin: 10px 0px;
  height: 150px;
}
.contact-section select {
  width: 100%;
  margin: 10px 0px 10px 0px;
  margin-bottom: 30px;
  height: 55px;
}
.get-in-touch {
  margin-bottom: 0px;
}

.get-in-touch form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.get-in-touch input,
.get-in-touch select,
.get-in-touch button {
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.get-in-touch button {
  width: 100%;
  background-color: white;
  color: #1c4753;
  cursor: pointer;
  font-weight: bold;
}

#service {
  color: gray;
  margin-left: 0px;
}

@media (max-width: 768px) {
  .contact {
    padding: 30px 0px;
  }

  .contact-section {
    margin: 0px;
  }

  .contact-section input {
    width: 95%;
    margin: 10px 0px;
    height: 40px;
  }

  .contact-section textarea {
    width: 95%;
    margin: 0px;
    height: 150px;
  }
}
