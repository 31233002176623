body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mobile-active{
  display: none;
}
.mobile-inactive{
  display: block;
}
button{
  cursor: pointer;
}

.hide-mobile {
  display: block !important;
}
.hide-desktop {
  display: none !important;
}
@media (max-width: 768px) {
  .mobile-active{
    display: block;
  }
  .mobile-inactive{
    display: none;
  }
  .hide-mobile {
    display: none !important;
  }
  .hide-desktop {
    display: block !important;
  }
}
