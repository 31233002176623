.popular-standards {
  margin-top: 50px;
}
.standard-card-color{
  fill: #1c4753;
}
.standard-card-size{
  height: 100px;
}
.standard-count {
  background-color: whitesmoke ;
  box-shadow: lightgray 1px 1px;
  width: 100px;
  height: 100px;
  margin-top: -30px;
  margin-right: -30px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
.standard-container {
  display: flex;
  margin: 70px 0px 60px 10px;
  align-items: "center";
  justify-content: center;
  flex-flow: row nowrap;
  
}
.standard-card {
  text-align: left;
  font-family: "Manrope";
  background-color: #1c4753 ;
  padding: 20px;
  padding-left: 50px;
  width: 70%;
  color: white;
}

.standard-card h3 {
  margin: 0px;
}

.standard-link {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 24px;
}

.popular-standards {
  padding: 32px;
  text-align: center;
  font-family: "Manrope";
}

.popular-standards h1 {
  color: rgb(30, 115, 190);
  font-size: 36px;
  line-height: normal;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 10px;
}

#subheader {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #333;
  margin-bottom: 15px;
}

.standard-cards {
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* gap: 16px; */
  /* grid-auto-rows: 1fr; */
  /* height: auto; */
}

@media (max-width: 768px) {

  .standard-card-size{
    height: auto;
  }
  .standard-count {
    width: 70px;
    height: 50px;
    margin-top: -20px;
    font-size: 25px;
  }

.standard-container {
  margin: 40px 0px 0px 0px;
  
}

.standard-card {
  text-align: left;
  font-family: "Manrope";
  background-color: #1c4753 ;
  padding: 10px;
  padding-left: 50px;
  width: 100%;
  color: white;
}

}
